var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sys-register-base',{attrs:{"loading":_vm.loading,"title":"Histórico de disparos","icon":"mdi-webhook","show":_vm.dialog,"maxWidth":"90%","actions":false,"model":_vm.model},on:{"update:show":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('span',[_vm._v("Somente histórico dos últimos 30 dias")]),(_vm.message)?_c('v-alert',{staticClass:"mt-2",attrs:{"value":true,"type":"success","dismissible":"","transition":"scale-transition","outlined":""}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.webhook.logs,"search":_vm.search,"loading":_vm.loading,"loading-text":"Carregando... Por favor, aguarde!","items-per-page":5,"fixed-header":"","height":"350"},scopedSlots:_vm._u([{key:"item.payload.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.payload.id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.status))]),_c('br'),_c('small',[_vm._v(_vm._s(item.http_status))])]}},{key:"item.payload.action_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.payload.action_type)+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.updated_at))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('emc-table-icon',{attrs:{"tool-tip":"Clique para visualizar o payload enviado","color":"info","icon":"mdi-code-json"},on:{"click":function($event){return _vm.showPayload(item.payload)}}}),_c('emc-table-icon',{attrs:{"tool-tip":"Clique para reprocessar item novamente","color":"green","icon":"mdi-sync-circle","loading":_vm.loadingReload},on:{"click":function($event){return _vm.callReloadWebhook(item)}}})],1)]}},{key:"item.exception",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.exception)}})]}}])}),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.dialogPayload),callback:function ($$v) {_vm.dialogPayload=$$v},expression:"dialogPayload"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Payload ")]),_c('v-card-text',{staticClass:"mt-2"},[_c('vue-json-pretty',{attrs:{"data":_vm.payload}})],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }